import * as React from 'react';
import { useState, useEffect } from 'react'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadButton from './DowloadButton.tsx';
import axios from 'axios';


interface Report {
    id: number;
    start_date: string;
    end_date: string;
    weeks: number;
    creation_date: string;
  }



export default function ReportTable() {
    const [reports, setReports] = useState<any>([]);  // TODO: fix type

    const asin = 'asdasd';
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    useEffect(
        () => {
            
            axios.get<Report[]>(`${apiBaseUrl}/api/report/all?asin=${asin}`)
            .then(function (response) {
                setReports(response.data);
            })
            .catch(function (error) {
                console.log(error); // TODO: recheck this part
            });
        },
        []
    );

    return (
    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
            <TableRow>
            <TableCell>Start Date</TableCell>
            <TableCell align="right">End Date</TableCell>
            <TableCell align="right">Weeks</TableCell>
            <TableCell align="right"></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {reports.map((row) => (
            <TableRow
                key={row.start_date}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                {row.start_date}
                </TableCell>
                <TableCell align="right">{row.end_date}</TableCell>
                <TableCell align="right">{row.weeks}</TableCell>
                <TableCell align="right">
                <DownloadButton />
                </TableCell>
            </TableRow>
            ))}
        </TableBody>
        </Table>
    </TableContainer>
    );
}
