import React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';

const DownloadButton = () => {
  const api_base_url = process.env.REACT_APP_API_BASE_URL;
  const report_id = 1;
  const handleDownload = async () => {
    try {
        const response = await axios.get(`${api_base_url}/api/report/dowload?id=${report_id}`, {
          responseType: 'blob',
        });
        
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.xlsx');
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Error downloading file:', error);
      }
  };

  return <Button 
    color="primary"
    variant="contained"
    onClick={handleDownload}>Download
    </Button>;
};

export default DownloadButton;
