import React from 'react';
import axios from 'axios';
import ReportTable from './ReportTable.tsx';
import HeaderBar from './HeaderBar.tsx';

const HomePage = () => {

  return <>
    <HeaderBar />
    <ReportTable />
  </>
};

export default HomePage;